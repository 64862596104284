import React from "react";
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import Notification from './Notification';

function TeamEntryForm() {
  // const [firstName, setFristName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [address, setAddress] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [title, setTitle] = useState("");
  const [message,setMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  // const [clearForm, setClearForm] = useState(false);

  // const url = "http://localhost:8000/"
  const url = "https://ssbc.club/db/all_posts.php";

  useEffect(() => {
    setTimeout(function () {
      setMsg("");
    }, 5000);
  }, [msg]);
  
  function Submit(e){
    document.getElementById("register").disabled=true;
    const formElm = document.querySelector('form');
    e.preventDefault();

    const fData = new FormData(formElm);

    // console.log(fData);

    fData.append('request', "memberreg");

    // calculate member valid date
    const currentYear = new Date().getFullYear();
    const currentDate = new Date();
    const comparisonDate = new Date(`${currentYear}-06-30`);
    const nextDate = new  Date(`${currentYear + 1}-06-30`);

    if (currentDate < comparisonDate) {
      fData.append('valid_till', currentDate.toISOString().split('T')[0]);     
    } else {
      fData.append('valid_till', nextDate.toISOString().split('T')[0]);
    }

    // console.log(fData);

    axios.post(url, fData)
      .then(response => {
        if(response.data === "Successful"){
          setTitle("Registration Successful");
          setMessage("Welcome to Stockholm Smashers Badminton Club.\n You can find yourself in our membership list now \n Sincerely, \n SSBC Admin");
          setModalShow(true);
          document.getElementById("memberForm").reset();
        }else {
          setTitle("Warning");
          setMessage(response.data);
          setModalShow(true);
          document.getElementById("register").disabled=false;
        }
        //alert(response.data);
        //document.getElementById("memberForm").reset();
        document.getElementById("register").disabled=false;
      })
      .catch(error => {
        alert(error)
        setTitle("Error");
        setMessage(error);
        setModalShow(true);
        document.getElementById("register").disabled=false;
      });

  }
  return (
    <div className="container">
      <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
        <div className="p-2 border border-white rounded text-center">
          <h5>Member registration Form</h5>
        </div>
        <form className="mt-2 needs-validation" id="memberForm" onSubmit={(e) => Submit(e)}>                   
          <div class="mt-2 form-group col-md-8">
              <label for="firstname">First Name: </label>
              <input
                type="text"
                className="form-control"
                id="firstname"
                name="first_name"
                required
              />
            </div>
            <div class="mt-2 form-group col-md-8">
              <label for="lastname">Last Name: </label>
              <input
                type="text"
                className="form-control"
                id="lastname"
                name="last_name"
                required
              />
            </div>
            <div className="mt-2 form-group col-md-8">
            <label for="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              aria-describedby="emailHelp"
              required
            />
          </div>
          <div class="mt-2 form-group col-md-4">
            <label for="phone">Phone Number: </label>
            <input
              type="number"
              class="form-control"
              id="phone"
              name="phone"
              pattern="[0-9]*"        
              required
            />
          </div>
          <div class="mt-2 form-group col-md-8">
              <label for="address">Address: </label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                required
              />
            </div>

          <div class="mt-3 form-check">
            <input
              type="checkbox"
              name="agreement"
              class="form-check-input"
              required
            />

            <label for="agreement">
              All entered information are correct
            </label>
          </div>

          <div className="form-group mt-3">
            <button type="submit" id="register" className="btn btn-primary btn-block">Register</button>
          </div>
        </form>
    
      </div>
      <Notification
        show={modalShow}
        onHide={() => setModalShow(false)}
        title = {title}
        message = {message}
      />
    
    </div>
    
  )
}
export default TeamEntryForm;