import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Contact from "./components/Contacts";
import Header from "./components/Header";
import MemberRegistrationForm from "./components/MemberRegistrationForm";
import Members from "./components/Members";
import Tournament2021 from './components/Tournament2021';
import Tournament2022 from './components/Tournament2022';
import Tournament2023 from './components/Tournament2023';
import Tournament2024 from './components/Tournament2024';
import Admin from "./components/Admin";
import Notification from "./components/Notification.js";
import AdminLogin from "./components/AdminLogin";
import PopupCard from "./components/PopupCard";
import RegisterTeam from "./components/RegisterTeam";
import Test from "./components/Test";



function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/registerteam" element={<RegisterTeam/>}/>
        <Route path="/registermember" element={<MemberRegistrationForm/>} />
        <Route path="/members" element={<Members />} />
        <Route path="/tournament2024" element={<Tournament2024/>} />
        <Route path="/tournament2023" element={<Tournament2023/>} />
        <Route path="/tournament2022" element={<Tournament2022 />} />
        <Route path="/tournament2021" element={<Tournament2021 />} />
        <Route path="/adminlogin" element={<AdminLogin/>} />
        <Route path="/admin" element={<Admin/>} />
        <Route path="/popupcard" element={<PopupCard/>} />
        <Route path="/notification" element={<Notification/>} />
        <Route path="/test" element={<Test/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;